import classNames from "classnames"
import Image, { MediaItem } from "frontastic/lib/image"

type Props = {
  src?: string,
  media?: MediaItem
  label: string
  className?: string
}

const Badge: React.FC<Props> = ({ src, media, label, className }) => {
  return (
    <div className={classNames(
      className,
      'btn btn-badge btn-icon'
    )}>
      <div className='icon--wrapper'>
        <Image
          {...
          (
            src
              ? { src: src }
              : { media: media }
          )}
          objectFit="contain"
          loading={'eager'}
          sizes="32px"
          alt={label}
        />
      </div>
      {label}
    </div>
  )
}

export default Badge
